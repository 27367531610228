'use client';
import React from 'react';

import {
  StyledProductCardImage,
  StyledProductCardWrapper,
  StyledVideoProductVideo,
} from './style';

type Props = {
  imageBaseURL: string;
  alt: string;
  videoBaseURL: string;
  preview_video: string;
  thumbnailBaseURL: string;
  width?: string;
  height?: string;
};

const Player = ({
  imageBaseURL,
  videoBaseURL,
  alt,
  preview_video: previewVideo,
  thumbnailBaseURL,
}: Props) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const type = previewVideo?.split('.')?.[1];

  return (
    <StyledProductCardWrapper
      elevation={3}
      sx={
        previewVideo
          ? {
              '&:hover': {
                '& .video-player': {
                  display: 'block',
                },
                '& .product-image': {
                  display: 'none',
                },
              },
            }
          : {}
      }
    >
      <StyledProductCardImage
        unoptimized
        className="product-image"
        src={imageBaseURL}
        alt={alt}
        width={0}
        height={0}
        placeholder="blur"
        blurDataURL={thumbnailBaseURL}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseOver={() => {
          setIsHovering(true);
        }}
        onTouchStart={() => {
          setIsHovering(true);
        }}
      />
      {!!previewVideo && isHovering && (
        <StyledVideoProductVideo
          className="video-player"
          autoPlay
          loop
          muted={true}
          controls={false}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
          onTouchEnd={() => {
            setIsHovering(false);
          }}
          poster={imageBaseURL}
        >
          <source src={videoBaseURL} type={`video/${type ?? 'webm'}`} />
        </StyledVideoProductVideo>
      )}
    </StyledProductCardWrapper>
  );
};

export default Player;
