/* eslint-disable @next/next/no-img-element */
import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import { Box, Chip, CircularProgress, Tooltip } from '@mui/material';

import { useProduct } from 'hooks/useProduct';
import CollectionPopup from 'libs/components/Popups/collection-popup';
import ProductPurchasePopup from 'libs/components/buy-popup';
import { FlexBetween } from 'libs/components/flex-box';
import { Small } from 'libs/components/typography';
import { Item } from 'libs/dt-types/response-types/category';
import { ItemType } from 'libs/dt-types/response-types/item';
import { capitiliazeFirstLetter } from 'libs/helpers/string';
import Player from './player';
import {
  StyledBazarCard,
  StyledDescription,
  StyledOverFlowBox,
  StyledTitle,
} from './style';

type Props = {
  item: Item;
  onDownloadClick?: () => void;
};

const ProductCard = ({ item }: Props) => {
  const {
    downloadFile: manageDownload,
    isDownloadLoading,
    downloadEligiblity,
    userMessage,
    userType,
    isLoggedIn,
    notLoggedInHandler,
    onDownloadHandler,
  } = useProduct(item);

  const [active, setActive] = React.useState('subscribe');
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState<any>(null);

  const downloadFile = (price: string, currency: string) => {
    if (!isLoggedIn) {
      notLoggedInHandler();
    } else {
      if (item?.isPurchased) {
        const itemDetails = {
          itemTitle: item.name,
          itemUrl: `https://designtemplate.io/${baseLink}/${item.slug}`,
        };

        onDownloadHandler(item?.id, item?.name, itemDetails, item?.isPurchased);
      } else if (!downloadEligiblity) {
        setActive('subscribe');

        setOpen(true);
      } else {
        if (
          userType?.toLowerCase() === 'premium'
            ? true
            : userType?.toLowerCase() === item?.type?.toLowerCase()
        ) {
          manageDownload(price, currency);
        } else {
          setActive('subscribe');
          setOpen(true);
        }
      }
    }
  };

  const videoBaseURL = `${process.env.CONTENT_BASE_URL}/public/videos/${item.preview_video}`;
  const imageBaseURL = `${process.env.CONTENT_BASE_URL}/${item.image_url}`;
  const thumbnailBaseURL = `${process.env.CONTENT_BASE_URL}/${item.preview_img}`;

  const baseLink =
    item.category.slug !== 'default'
      ? `${item.category.slug}-${item?.mastercategory?.slug}`
      : item.mastercategory?.slug;

  const authorName = `${
    capitiliazeFirstLetter(item.users.first_name) || 'DesignTemplate'
  } ${capitiliazeFirstLetter(item.users.last_name) || 'Author'}`;

  return (
    <StyledBazarCard elevation={3}>
      <Link href={`/${baseLink}/${item.slug}`}>
        <Player
          alt={item.name}
          preview_video={item.preview_video}
          imageBaseURL={imageBaseURL}
          videoBaseURL={videoBaseURL}
          thumbnailBaseURL={thumbnailBaseURL}
        />
      </Link>
      <Box paddingX={1.2} paddingTop={0.875}>
        <FlexBetween>
          <StyledTitle>
            <Link href={`/${baseLink}/${item.slug}`}>{item.name}</Link>
          </StyledTitle>
          {item.type === ItemType.PRO && (
            <Tooltip title="Pro Item">
              <img
                src={`${process.env.CONTENT_BASE_URL}/pro.gif`}
                width={30}
                height={30}
                alt="Pro Item"
              />
            </Tooltip>
          )}
          {item.type === ItemType.FREE && (
            <Tooltip title="Free Item">
              <img
                src={`${process.env.CONTENT_BASE_URL}/free.gif`}
                width={30}
                height={30}
                alt="Free Item"
              />
            </Tooltip>
          )}
          {item.type === ItemType.PREMIUM && (
            <Tooltip title="Premium Item">
              <img
                src={`${process.env.CONTENT_BASE_URL}/pro.gif`}
                width={30}
                height={30}
                alt="Premium Item"
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </Tooltip>
          )}
        </FlexBetween>
        <Small color="#6E5BE4">By {authorName}</Small>
        <Box
          display="flex"
          justifyContent="space-between"
          paddingY={1.5}
          columnGap={0.7}
        >
          <Box display="flex" alignItems="center" gap={0.875}>
            <StyledDescription>{item.short_description}</StyledDescription>
          </Box>
          <Box display="flex" alignItems="center" gap={1.5}>
            <CollectionsBookmarkOutlinedIcon
              sx={{
                color: '#796ed3',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                if (!isLoggedIn) {
                  notLoggedInHandler();
                } else {
                  setActive('collection');
                  setId(item.new_id);
                  setOpen(true);
                }
              }}
            />
            {!isDownloadLoading ? (
              <Image
                unoptimized
                src={`${process.env.CONTENT_BASE_URL}/static/next/public/assets/svg/listing/download-icon.svg`}
                alt="heart"
                width={24}
                height={24}
                style={{ cursor: 'pointer' }}
                role="button"
                onClick={() => {
                  downloadFile(item.price, 'INR');
                }}
              />
            ) : (
              <CircularProgress size={'24px'} />
            )}
          </Box>
        </Box>
      </Box>
      <StyledOverFlowBox>
        {item?.itemTags?.map((tag) => {
          return (
            <Chip
              label={tag.name}
              key={tag.id}
              sx={{
                borderRadius: '6px',
                cursor: 'pointer',
                color: 'black',
                fontSize: '12px',
                backgroundColor: '#D7D4F2',
                opacity: 0.7,
              }}
              component="a"
              href={`/${baseLink}/${tag.slug}`}
              color="primary"
              aria-labelledby={tag.name}
              aria-label={`Navigate to ${tag.name}`}
            />
          );
        })}
      </StyledOverFlowBox>
      <ProductPurchasePopup
        downloadEligiblity={downloadEligiblity ?? false}
        open={active === 'subscribe' && open === true ? true : false}
        downloadFile={manageDownload}
        userMessage={userMessage}
        userType={userType ?? 'FREE'}
        item={item}
        isDownloadLoading={isDownloadLoading}
        setOpen={setOpen}
      />
      {id && (
        <CollectionPopup
          open={active === 'collection' && open === true ? true : false}
          setOpen={setOpen}
          id={id}
          getNames={false}
        />
      )}
    </StyledBazarCard>
  );
};

export default ProductCard;
