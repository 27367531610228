import { Item } from 'libs/dt-types/response-types/category';
import { ItemType } from 'libs/dt-types/response-types/item';
import { setClarityEvent } from 'libs/helpers/set-clarity-events';
import { generateUserMessage, PlanType } from 'utils/user-message';
import useDownload from './useDownload';

export const useProduct = (item: Item) => {
  const {
    data,
    onDownloadHandler,
    purchaseHandler,
    isDownloadLoading,
    isLoggedIn,
    notLoggedInHandler,
    isLoading,
  } = useDownload();

  const userData = data?.data?.userDetails;
  const userType = userData?.name;
  const totalLimit = userData?.limit as any;

  const downloadEligiblity = data?.data?.eligibleForDownload;

  const isPremium =
    userType?.toLowerCase() === 'premium' || userType?.toLowerCase() === 'pro';

  const isEligible = isPremium
    ? downloadEligiblity
    : userType?.toLowerCase() === item?.type?.toLowerCase() &&
      downloadEligiblity;

  const imageUrl = `${process.env.CONTENT_BASE_URL}/${item.image_url}`;
  const videoBaseURL = `${process.env.CONTENT_BASE_URL}/public/videos/${item.preview_video}`;

  const baseLink = `${
    item.category.slug !== 'default' ? item.category.slug + '-' : ''
  }${item.mastercategory?.slug}`;

  const currentFileLimitTotal =
    totalLimit?.[`${item?.type.toLowerCase()}_daily`] ?? 0;

  const downloadFile = (
    price: string,
    currency: string,
    isPurchased?: boolean
  ) => {
    setClarityEvent('donwload_button_clicked', 'confirmed');

    const itemDetails = {
      itemTitle: item.name,
      itemUrl: `https://designtemplate.io/${baseLink}/${item.slug}`,
      imageURL: item.image_url,
    };

    if (!isLoggedIn) {
      notLoggedInHandler();
    } else {
      if (isPurchased) {
        onDownloadHandler(item.id, item.name, itemDetails, isPurchased);
      } else if (isEligible) {
        onDownloadHandler(item.id, item.name, itemDetails, false);
      }
    }
  };

  const userCase = isLoggedIn
    ? isEligible
      ? 'logged-in-has-limit'
      : 'logged-in-exhausted-limit'
    : 'not-logged-in';

  const otherCase = !isLoggedIn
    ? 'not-logged-in'
    : item?.type !== userType
    ? isEligible && userType?.toLowerCase() === 'premium'
      ? 'logged-in-has-limit'
      : userType?.toLowerCase() === item?.type?.toLowerCase()
      ? userCase
      : userCase === 'logged-in-exhausted-limit' &&
        userType?.toLowerCase() === 'premium'
      ? 'logged-in-exhausted-limit'
      : 'default'
    : userCase;

  const newCase =
    !isLoggedIn && item.type === ItemType.FREE
      ? 'not-logged-in-free-file'
      : otherCase;

  const userMessage = generateUserMessage(
    newCase,
    (userType?.toLowerCase() as PlanType) ?? item?.type?.toLowerCase(),
    item?.type?.toLowerCase() ?? 'Premium',
    data?.data?.totalDownloads ?? 0,
    data?.data?.totalDownloadLimit ?? 99
  );

  return {
    imageUrl,
    baseLink,
    userType,
    downloadEligiblity: isEligible,
    onDownloadHandler,
    downloadFile,
    title: item.name,
    isDownloadLoading: isDownloadLoading,
    currentFileLimitTotal,
    userMessage,
    videoBaseURL,
    isLoggedIn,
    notLoggedInHandler,
    purchaseHandler,
    isLoading,
  };
};
